import range from "lodash/range";
import PropTypes from "prop-types";
import { memo, Fragment } from "react";

const IndexedPagination = (props) => {
  const {
    currentPage,
    pageCount,
    marginPagesDisplayed,
    pageRangeDisplayed,
    renderNext,
    renderPrev,
    renderEllipses,
    renderPageNumber,
  } = props;

  const renderItems = (items) => (
    <Fragment>
      {renderPrev({ disabled: currentPage <= 1 })}
      {items}
      {renderNext({ disabled: currentPage >= pageCount })}
    </Fragment>
  );

  if (!pageCount || pageCount === 0) {
    return renderItems([]);
  }

  if (pageCount <= pageRangeDisplayed) {
    return renderItems(range(1, pageCount + 1).map(renderPageNumber));
  }

  let addedBreak = false;
  let leftSide = pageRangeDisplayed / 2;
  let rightSide = pageRangeDisplayed - leftSide;

  // If the currentPage page index is on the default right side of the pagination,
  // we consider that the new right side is made up of it (= only one break element).
  // If the selected page index is on the default left side of the pagination,
  // we consider that the new left side is made up of it (= only one break element).
  if (currentPage > pageCount - pageRangeDisplayed / 2) {
    rightSide = pageCount - currentPage;
    leftSide = pageRangeDisplayed - rightSide;
  } else if (currentPage < pageRangeDisplayed / 2) {
    leftSide = currentPage;
    rightSide = pageRangeDisplayed - leftSide;
  }

  const items = range(1, pageCount + 1).reduce((newItems, index) => {
    // condition 1
    // If the page index is lower than the margin defined,
    // the page has to be displayed on the left side of
    // the pagination.

    // condition 2
    // If the page index is greater than the page count
    // minus the margin defined, the page has to be
    // displayed on the right side of the pagination.

    // condition 3
    // If the page index is near the currentPage page index
    // and inside the defined range (pageRangeDisplayed)
    // we have to display it (it will create the center
    // part of the pagination).
    if (
      index <= marginPagesDisplayed ||
      index > pageCount - marginPagesDisplayed ||
      (index >= currentPage - leftSide && index <= currentPage + rightSide)
    ) {
      addedBreak = false;
      return [...newItems, renderPageNumber(index)];
    }

    if (!addedBreak) {
      addedBreak = true;
      return [...newItems, renderEllipses()];
    }

    return newItems;
  }, []);

  return renderItems(items);
};

IndexedPagination.propTypes = {
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  marginPagesDisplayed: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  renderNext: PropTypes.func,
  renderPrev: PropTypes.func,
  renderEllipses: PropTypes.func,
  renderPageNumber: PropTypes.func,
};

IndexedPagination.defaultProps = {
  currentPage: 0,
  pageCount: 0,
  marginPagesDisplayed: 0,
  pageRangeDisplayed: 0,
  renderNext: null,
  renderPrev: null,
  renderEllipses: null,
  renderPageNumber: null,
};

export default memo(IndexedPagination);
