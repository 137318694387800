import { css } from "aphrodite";
import { useCallback } from "react";
import Helmet from "react-helmet-async";

import ContentContainer from "components/Common/Layout/ContentContainer";
import LoadingOverlay from "components/Common/LoadingOverlay";
import PostList from "pages/Blog/PostList";
import ListPaginationControls from "pages/Common/ListPaginationControls";
import Error404 from "pages/Error404/Async";

import * as sortConstants from "constants/sort";
import loadPodcastsList from "sagas/pagination/lists/loadPodcastsList";
import { BLOG_BASE_PATH } from "utils/blog";
import { generateMetaArray } from "utils/meta";

import useList from "hooks/useList";
import useLoadListEffect from "hooks/useLoadListEffect";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

require("styles/css/blog.css");

const baseStyles = {
  wrapper: {
    background: "#fff",
    ...gStyles.fontRegular,
  },
  inner: {
    margin: "1.5rem auto 1.5rem",
    [ScreenSizes.lgAndAbove]: {
      marginTop: "3rem",
      marginBottom: "3rem",
    },
    maxWidth: "70rem",
  },

  title: {
    ...gStyles.primaryPageTitle,
    textAlign: "left",
    color: "#000",
    padding: "initial",
    margin: "initial",
    width: "100%",
    maxWidth: "none",
  },
  subtitle: {
    marginBottom: "2.5rem",
    maxWidth: "45rem",
    lineHeight: 1.8,
  },
  posts: {
    width: "auto",
    margin: "0 -12px",
    boxSizing: "border-box",
  },
  post: {
    width: "100%",
    padding: "0 12px",
    boxSizing: "border-box",
    [ScreenSizes.smAndAbove]: {
      width: "50%",
      float: "left",
    },
    [ScreenSizes.lgAndAbove]: {
      width: "33.3333%",
    },
  },
  thumbnailContainer: {
    width: "100%",
    display: "block",
    position: "relative",
  },
  thumbnail: {
    background: "#f9f9f9",
    paddingBottom: "100%",
    marginBottom: "1rem",
    display: "block",
    height: 0,
  },
  thumbnailImg: {
    width: "100%",
    height: "auto",
  },
  postBody: {
    [ScreenSizes.smAndAbove]: {
      minHeight: "16rem",
    },
    marginBottom: "2.5rem",
  },
  postTitle: {
    ...gStyles.avalonSemiBold,
    fontSize: "1.4rem",
    marginBottom: "1rem",
  },
  byline: {
    ...gStyles.fontMediumItalic,
  },
  categories: {
    marginBottom: "1.5rem",
  },
  category: {
    display: "inline-block",
  },
  categoryText: {
    ...gStyles.fontSemiBold,
    display: "inline-block",
    background: "#d6dadd",
    color: "#818ba5",
    borderRadius: "4em",
    padding: "0.3em 0.8em 0.2em",
    fontSize: "0.7em",
  },
};

function BlogIndex(props) {
  const { styles } = useStyles(baseStyles, props);
  const { list, is404, ids, currentPage } = useList("blog_index");
  const paginationUri = useCallback(
    ({ pageNumber }) => `${BLOG_BASE_PATH}?page=${pageNumber}`,
    []
  );

  useLoadListEffect("blog_index", {
    sort: sortConstants.SORT_ORDER_RECENT,
    list_type: "posts_filtered",
    loadListAction: loadPodcastsList,
    entity_type: "post",
    pageSize: 24,
  });

  if (is404) {
    return <Error404 />;
  }
  if (list.get("loading")) {
    return <LoadingOverlay key="blogIndexLoadingOverlay" />;
  }

  return (
    <div className={css(styles.wrapper)}>
      <Helmet
        title="Podcast Articles"
        meta={generateMetaArray({
          title: "Podcast Articles by Podchaser",
          description:
            "News, updates, podcast creator interviews, podcast spotlights, and so much more from the world of podcasting.",
        })}
      />
      <ContentContainer>
        <div className={css(styles.inner)}>
          <h1 data-id="title-articles" className={css(styles.title)}>
            Articles {currentPage > 1 && ` - Page ${currentPage}`}
          </h1>
          <p className={css(styles.subtitle)}>
            Take a deep dive into the podcasting world with exclusive
            interviews, biographies and insights into podcasts and podcast
            creators of all sizes.
          </p>
          <PostList ids={ids} />
          <ListPaginationControls
            listKey="blog_index"
            generateUri={paginationUri}
          />
        </div>
      </ContentContainer>
    </div>
  );
}

export default BlogIndex;
