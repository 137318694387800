import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { css } from "aphrodite";
import { format } from "date-fns/format";
import PropTypes from "prop-types";
import { memo, Fragment } from "react";
import { Link } from "react-router-dom";

import BlogCategoryLink from "./BlogCategoryLink";
import BlogCategoryList from "./BlogCategoryList";

import { SHORT_DATE_ONLY_FORMAT } from "constants/date";
import { selectSpecificPost } from "selectors/blog";
import {
  generateBlogAuthorPath,
  generateBlogPostPath,
  getPostMedia,
} from "utils/blog";
import htmlDecode from "utils/misc/htmlDecode";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useUserHasPermission from "hooks/useUserHasPermission";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  post: {
    width: "100%",
    padding: "0 12px",
    boxSizing: "border-box",
    [ScreenSizes.mdAndAbove]: {
      width: "50%",
      float: "left",
    },
  },
  thumbnailContainer: {
    width: "100%",
    display: "block",
    position: "relative",
  },
  thumbnail: {
    background: "#f9f9f9",
    paddingBottom: "50%",
    marginBottom: "1rem",
    display: "block",
    height: 0,
    position: "relative",
    borderRadius: 8,
    boxShadow: "0 0 1px rgba(0, 0, 0, 0.4)",
    overflow: "hidden",
  },
  thumbnailImgContainer: {
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
  thumbnailImg: {
    width: "100%",
    height: "auto",
    opacity: 0,
  },
  postBody: {
    [ScreenSizes.smAndAbove]: {
      // minHeight: '10rem',
    },
    marginBottom: "2.5rem",
  },
  postTitle: {
    ...gStyles.avalonSemiBold,
    fontSize: "1.4rem",
    marginBottom: "1rem",
    display: "block",
  },
  byline: {
    ...gStyles.fontMediumItalic,
  },
  postDate: {
    ...gStyles.fontSemiBold,
    color: "#797676",
    fontSize: "0.75em",
  },
  dateLine: {
    display: "flex",
    justifyContent: "space-between",
  },
  editLink: {
    display: "block",
  },
};

function BlogPostCard(props) {
  const { styles } = useStyles(baseStyles, props);
  const post = useReduxState(
    (state) => selectSpecificPost(state, props.id),
    [props.id]
  );
  const isEditor = useUserHasPermission("edit blog");
  const media = getPostMedia(post, "1536x1536") || getPostMedia(post, "large");

  const { url, width, height } = media || {};

  if (!post) {
    return <div />;
  }

  return (
    <div data-id="blog-post-card" className={css(styles.post)}>
      <Link
        className={css(styles.thumbnailContainer)}
        data-id="blog-post-image"
        to={generateBlogPostPath(post)}
      >
        <span className={css(styles.thumbnail)}>
          {media && (
            <span
              className={css(styles.thumbnailImgContainer)}
              style={{ backgroundImage: `url('${url}')` }}
            >
              <img
                src={url}
                width={width}
                height={height}
                className={css(styles.thumbnailImg)}
                alt={htmlDecode(post.getIn(["title", "rendered"]))}
                title={htmlDecode(post.getIn(["title", "rendered"]))}
              />
            </span>
          )}{" "}
        </span>
      </Link>
      <div className={css(styles.postBody)}>
        <Link
          className={css(styles.postTitle)}
          data-id="blog-post-title"
          to={generateBlogPostPath(post)}
        >
          {htmlDecode(post.getIn(["title", "rendered"]))}
        </Link>
        <div className={css(styles.byline)}>
          <BlogCategoryList
            categories={post.getIn(["_embedded", "wp:term", "0"]).slice(0, 2)}
            appendItems={
              <Fragment>
                {isEditor && post.get("status") !== "publish" && (
                  <BlogCategoryLink
                    id="status-0"
                    name={post.get("status")}
                    icon={faCog}
                    inline
                  />
                )}
                <BlogCategoryLink
                  dataId="blog-post-author"
                  link={generateBlogAuthorPath(
                    post.getIn(["_embedded", "author", "0", "slug"])
                  )}
                  id="author-0"
                  name={post.getIn(["_embedded", "author", "0", "name"])}
                  icon={faUser}
                  inline
                />
              </Fragment>
            }
            inline
          />
          <div className={css(styles.dateLine)}>
            <div className={css(styles.postDate)}>
              {format(post.get("date"), SHORT_DATE_ONLY_FORMAT)}
            </div>
            {isEditor && (
              <a
                className={css(styles.editLink)}
                href={`https://blog.podchaser.com/wp-admin/post.php?post=${post.get(
                  "id"
                )}&action=edit`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Edit
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

BlogPostCard.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number,
};
BlogPostCard.defaultProps = {
  index: 0,
};

export default memo(BlogPostCard);
