const htmlEntities = {
  nbsp: " ",
  cent: "¢",
  pound: "£",
  yen: "¥",
  euro: "€",
  copy: "©",
  reg: "®",
  lt: "<",
  gt: ">",
  quot: '"',
  amp: "&",
  apos: "'",
};

export default function htmlDecode(str) {
  return str.replace(/\&([^;]+);/g, (entity, entityCode) => {
    let match;

    if (entityCode in htmlEntities) {
      return htmlEntities[entityCode];
      /* eslint no-cond-assign: 0 */
    }
    if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
      return String.fromCharCode(parseInt(match[1], 16));
      /* eslint no-cond-assign: 0 */
    }
    if ((match = entityCode.match(/^#(\d+)$/))) {
      return String.fromCharCode(~~match[1]);
    }
    return entity;
  });
}
