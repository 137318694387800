import { normalize } from "normalizr";
import qs from "qs";

import { postSchema } from "../schema/blog";
import client from "./client";

export function loadSpecificBlogPost({ slug }) {
  return client
    .get(`/blog/posts/${slug}`)
    .then((response) => normalize(response.data, postSchema));
}

export function loadBlogPosts({
  offset = 0,
  count,
  category = null,
  author = null,
}) {
  return client
    .get(
      `/blog/posts?${qs.stringify({
        count,
        page: Math.floor(offset / count) + 1,
        category,
        author,
      })}`
    )
    .then((response) => {
      const postData = normalize(response.data.posts, [postSchema]);
      return {
        ...postData,
        additional: response.data.entities,
        meta: response.data.meta,
      };
    });
}
