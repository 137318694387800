import { css } from "aphrodite";
import { List } from "immutable";
import PropTypes from "prop-types";

import BlogPostCard from "./BlogPostCard";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  posts: {
    width: "auto",
    margin: "0 -12px",
    boxSizing: "border-box",
    display: "flex",
    flexWrap: "wrap",
  },
  clear: {
    clear: "both",
  },
};

function PostList(props) {
  const { styles } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.posts)}>
      {props.ids.map((id, index) => {
        const key = `${id}-${index}`;

        return <BlogPostCard id={id} key={key} index={index} />;
      })}
      <div className={css(styles.clear)} />
    </div>
  );
}

PostList.propTypes = {
  ids: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)])
    .isRequired,
};

export default PostList;
