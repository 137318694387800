import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css, StyleSheet } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback } from "react";

import StandardButton from "components/Buttons/StandardButton";
import IndexedPagination from "components/Common/Pagination/IndexedPagination";

import generateTransition from "utils/generateTransition";

import useList from "hooks/useList";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const styles = StyleSheet.create({
  outer: {
    display: "block",
    textAlign: "center",
    marginTop: "1.75rem",
  },
  paginateContainer: {
    display: "flex",
  },
  buttonContainer: {
    display: "inline-block",
    minWidth: "2em",
    margin: "0 0.1rem",
  },
  prevButtonContainer: {
    margin: "0 0.3rem",
  },
  nextButtonContainer: {
    margin: "0 0.3rem",
  },
  pageNumber: {
    maxWidth: 50,
    minWidth: "1em",

    [ScreenSizes.lgAndAbove]: {
      minWidth: "3em",
    },
  },
  button: {
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    minHeight: "3.5em",
    background: "none",
    boxShadow: "none",
    transition: generateTransition({
      targets: ["box-shadow", "background"],
      speed: "300ms",
    }),

    ...getHoverQuery({
      background: "#fff",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.14)",
    }),
  },
  buttonDisabled: {
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    minHeight: "3.5em",
    background: "none",
    boxShadow: "none",
    cursor: "default",
  },
  currentButton: {
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    minHeight: "3.5em",
    background: colours.oldSecondary,
    color: "#fff",
    boxShadow: "none",
    cursor: "default",
  },
  ellipsisContainer: {
    display: "inline-block",
    margin: "0 0.3rem",
  },
  ellipsis: {
    display: "inline-block",
  },
});

const ListPaginationControls = (props) => {
  const { generateUri, listKey } = props;
  const { currentPage, totalPages } = useList(listKey);
  const { isWindowSizeOrMore } = useWindowSize();

  const renderPrev = useCallback(
    ({ disabled }) => (
      <div
        key="prev"
        className={css(styles.buttonContainer, styles.prevButtonContainer)}
      >
        <StandardButton
          data-id="list-pagination-prev"
          label={isWindowSizeOrMore("large") ? "Previous" : "Prev"}
          link
          to={generateUri({ pageNumber: currentPage - 1 })}
          disabled={disabled}
          variation="white"
          styles={disabled ? styles.buttonDisabled : styles.button}
        />
      </div>
    ),
    [currentPage, generateUri, isWindowSizeOrMore]
  );

  const renderNext = useCallback(
    ({ disabled }) => (
      <div
        key="next"
        className={css(styles.buttonContainer, styles.nextButtonContainer)}
      >
        <StandardButton
          data-id="list-pagination-next"
          label="Next"
          link
          to={generateUri({ pageNumber: currentPage + 1 })}
          disabled={disabled}
          variation="white"
          styles={disabled ? styles.buttonDisabled : styles.button}
        />
      </div>
    ),
    [currentPage, generateUri]
  );

  const renderPageNumber = useCallback(
    (index) => (
      <div
        key={index}
        className={css(
          styles.buttonContainer,
          styles.pageNumber,
          currentPage === index && styles.currentPageNumber
        )}
      >
        <StandardButton
          data-id={`list-pagination-button-${index}`}
          label={index}
          link
          to={generateUri({ pageNumber: index })}
          variation="white"
          styles={
            (currentPage === index && styles.currentButton) || styles.button
          }
        />
      </div>
    ),
    [currentPage, generateUri]
  );

  const renderEllipses = useCallback(
    () => (
      <FontAwesomeIcon
        icon={faEllipsisH}
        key="ellipsis"
        className={css(styles.ellipsisContainer)}
      />
    ),
    []
  );

  return (
    <div data-id="list-pagination-controls" className={css(styles.outer)}>
      <IndexedPagination
        pageCount={totalPages}
        pageRangeDisplayed={isWindowSizeOrMore("large") ? 5 : 3}
        marginPagesDisplayed={isWindowSizeOrMore("large") ? 2 : 1}
        currentPage={currentPage}
        renderPrev={renderPrev}
        renderNext={renderNext}
        renderPageNumber={renderPageNumber}
        renderEllipses={renderEllipses}
      />
    </div>
  );
};

ListPaginationControls.propTypes = {
  listKey: PropTypes.string.isRequired,
  generateUri: PropTypes.func.isRequired,
};

export default ListPaginationControls;
